export const defaultRentalUnitLeaseTerms = [
  {
    name: "rent",
    label: "Rent",
    value: 1500,
    inputType: "number",
    description: "How much you are going to charge for rent per period",
    type: "lease",
  },
  {
    name: "rent_collection_day",
    label: "Rent Collection Day",
    inputType: "select",
    options: [
      { value: 1, label: "1st" },
      { value: 2, label: "2nd" },
      { value: 3, label: "3rd" },
      { value: 4, label: "4th" },
      { value: 5, label: "5th" },
      { value: 6, label: "6th" },
      { value: 7, label: "7th" },
      { value: 8, label: "8th" },
      { value: 9, label: "9th" },
      { value: 10, label: "10th" },
      { value: 11, label: "11th" },
      { value: 12, label: "12th" },
      { value: 13, label: "13th" },
      { value: 14, label: "14th" },
      { value: 15, label: "15th" },
      { value: 16, label: "16th" },
      { value: 17, label: "17th" },
      { value: 18, label: "18th" },
      { value: 19, label: "19th" },
      { value: 20, label: "20th" },
      { value: 21, label: "21st" },
      { value: 22, label: "22nd" },
      { value: 23, label: "23rd" },
      { value: 24, label: "24th" },
      { value: 25, label: "25th" },
      { value: 26, label: "26th" },
      { value: 27, label: "27th" },
      { value: 28, label: "28th" },
      { value: 29, label: "29th" },
      { value: 30, label: "30th" },
      { value: 31, label: "31st" },
    ],
    value: 1,
    description: "What day of the month rent is due",
    type: "lease",
  },
  {
    name: "rent_frequency",
    label: "Rent Frequency",
    inputType: "select",
    options: [
      { value: "day", label: "Daily" },
      { value: "month", label: "Monthly" },
      { value: "week", label: "Weekly" },
      { value: "year", label: "Yearly" },
    ],
    value: "month",
    description:
      "How often you are going to charge rent. This can be daily, monthly, weekly, or yearly",
    type: "lease",
  },
  {
    name: "combine_payments",
    label: "Combine Payments",
    inputType: "select",
    options: [
      { value: "separate", label: "Separate" },
      { value: "combined", label: "Combined" },
    ],
    value: "separate",
    description:
      "Choose if you would like to combine all payments into one invoice for the lease",
    type: "lease",
  },
  {
    name: "term",
    label: "Term",
    inputType: "number",
    value: 12,
    description: "How long the lease is for in the selected rent frequency",
    type: "lease",
  },
  {
    name: "late_fee",
    label: "Late Fee",
    inputType: "number",
    value: 100,
    description: "How much you will charge for late rent payments",
    type: "lease",
  },
  {
    name: "security_deposit",
    label: "Security Deposit",
    inputType: "number",
    value: 100,
    description:
      "How much the tenant will pay for a security deposit. This will be returned to them at the end of the lease if all conditions are met",
    type: "lease",
  },
  {
    name: "gas_included",
    label: "Include Gas Bill In Rent",
    inputType: "switch",
    value: true,
    description: "Indicates if gas bill is included in the rent",
    type: "lease",
  },
  {
    name: "electricity_included",
    label: "Include Electricity Bill In Rent",
    inputType: "switch",
    value: true,
    description: "Indicates if electricity bill is included in the rent",
    type: "lease",
  },
  {
    name: "water_included",
    label: "Include Water Bill In Rent",
    inputType: "switch",
    value: true,
    description: "Indicates if water bill is included in the rent",
    type: "lease",
  },
  {
    name: "repairs_included",
    label: "Include Repairs In Rent",
    inputType: "switch",
    value: true,
    description:
      "Indicates if repairs are included in the rent. If not, the tenant will be responsible for all repair bills",
    type: "lease",
  },
  {
    name: "grace_period",
    label: "Grace Period",
    inputType: "number",
    value: 0,
    description: "How many days before the first rent payment is due",
    type: "lease",
  },
  {
    name: "lease_cancellation_notice_period",
    label: "Lease Cancellation Notice Period",
    inputType: "number",
    value: 14,
    description:
      "How many months a tenant must wait before the end of the lease to cancel the lease",
    type: "lease",
  },
  {
    name: "lease_cancellation_fee",
    label: "Lease Cancellation Fee",
    inputType: "number",
    value: 100,
    description:
      "How much the tenant must pay to cancel the lease before the end of the lease",
    type: "lease",
  },
  {
    name: "lease_renewal_notice_period",
    label: "Lease Renewal Notice Period",
    inputType: "number",
    value: 2,
    description:
      "How many months before the end of the lease the tenant must notify the owner of their intent to renew the lease",
    type: "lease",
  },
  {
    name: "lease_renewal_fee",
    label: "Lease Renewal Fee",
    inputType: "number",
    value: 100,
    description: "How much the tenant must pay to renew the lease",
    type: "lease",
  },
];
